import { createContext, useState } from "react";

const PaletaContext = createContext();


const ContextProvider = ({children}) => {
    const [colorObtenido, setColorObtenido] = useState("#ff0");
    const [popUp, setPopUp] = useState(false);

    const obtenerColor = (value) =>{
        setColorObtenido(value);
    }

    return(
        <PaletaContext.Provider
            value = {{
                colorObtenido,
                popUp,
                obtenerColor,
                setPopUp
            }}
        >{children}</PaletaContext.Provider>
    )
}


export {
    ContextProvider
}

export default PaletaContext;